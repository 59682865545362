import SimpleSchema from 'simpl-schema';
import isNil from 'ramda/src/isNil';

export const deepCopy = (src) => {
  if (src === undefined) return src;
  const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  const reviver = (key, value) => (typeof value === 'string' && dateRegex.test(value) ? new Date(value) : value);
  return JSON.parse(JSON.stringify(src), reviver);
};

export const getModel = (type, defaultValue) => {
  if (type === String) return defaultValue || '';
  if (type === Array) return defaultValue || [];
  if (type instanceof SimpleSchema) {
    const model = {};
    type.objectKeys().forEach((key) => {
      model[key] = getModel(type.getDefinition(key).type[0].type, defaultValue && defaultValue[key]);
    });
    return model;
  }
};

const getFieldDefault = (fieldType) => {
  if (fieldType === String) return '';
};

const getArrayDefault = (schema, key, defaultValue) => {
  if (!defaultValue) return [];
  const arrayElemType = schema.getDefinition(`${key}.$`).type[0].type;
  if (arrayElemType instanceof SimpleSchema) {
    return defaultValue.map((elem) => getModelFromSchema(arrayElemType, elem));
  }
  if (arrayElemType === Array) {
    return defaultValue.map((elem) => getArrayDefault(schema, `${key}.$`, elem));
  }
  return defaultValue.map(deepCopy);
};

export const getModelFromSchema = (schema, defaultValue = {}) => {
  const model = {};
  schema.objectKeys().forEach((key) => {
    const keyType = schema.getDefinition(key).type[0].type;
    if (keyType instanceof SimpleSchema) {
      model[key] = defaultValue[key] ? getModelFromSchema(keyType, defaultValue[key]) : undefined;
    } else if (keyType === Array) {
      model[key] = getArrayDefault(schema, key, defaultValue[key]);
    } else {
      model[key] = !isNil(defaultValue[key]) ? deepCopy(defaultValue[key]) : getFieldDefault(keyType);
    }
  });
  return model;
};
