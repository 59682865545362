import { getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';

import { currency } from '@/locale/numberConfig';
import i18n from '@/imports/startup/client/i18n';

const formatTerm = (i18n, term) => {
  if (!term) return '-';
  if (term.type === 'PRICING') {
    if (term.pricingMethod === 'FIXED_PRICE') {
      const formattedPrice = (term.price / 100).toLocaleString(i18n.locale, currency);
      return `${formattedPrice} ${i18n.t('term.termFormatter.fixed')}`;
    }
    if (term.pricingMethod === 'PRICE_LIST') {
      const today = DateTime.utc().startOf('day').toJSDate();
      const price = term.priceList.prices
        ?.find(({ productId }) => productId === term.itemId)
        ?.data.filter(({ date }) => new Date(date) <= today)
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]?.price;
      if (!price) return term.priceList.name;
      const formattedPrice = (price / 100).toLocaleString(i18n.locale, currency);
      return `${term.priceList.name} (${formattedPrice})`;
    }
    if (term.pricingMethod === 'PRICE_INDEX') {
      return `${i18n.t('term.termFormatter.basedOn')} ${term.priceIndexName}`;
    }
    if (term.pricingMethod === 'PER_ORDER') {
      return i18n.t('term.termFormatter.perOrder');
    }
  }

  if (term.type === 'PROMOTION') {
    if (term.rewardType === 'DISCOUNT' && term.rewardRealizationTime === 'ON_BILL') {
      return `${term.rewardValue}% ${i18n.t('term.termFormatter.discount')}`;
    }
    if (term.rewardType === 'QUANTITY' && term.conditionType === 'QUANTITY_AMOUNT') {
      return `${term.conditionValue} + ${term.rewardValue} ${i18n.t('term.termFormatter.free')}`;
    }
  }
  return i18n.t('term.termFormatter.unknownTerm');
};

export const useTermFormatter = () => {
  const instance = getCurrentInstance().proxy;
  return { format: formatTerm.bind(null, instance.$i18n) };
};

export const format = formatTerm.bind(null, i18n);
