import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export const useUpdateTerm = () => {
  const { mutate, loading, onDone, onError } = useMutation(TERM_UPDATE_MUTATION);

  return {
    updateTerm: mutate,
    loading,
    onDone,
    onError,
  };
};

export const useUpdateTermNew = () => {
  const { mutate, loading, onDone, onError } = useMutation(TERM_UPDATE_MUTATION_NEW);

  return {
    updateTerm: mutate,
    loading,
    onDone,
    onError,
  };
};

const TERM_UPDATE_MUTATION_NEW = gql`
  mutation termUpdateMutation($termId: ID!, $termUpdateInput: TermUpdateInputNew!) {
    termUpdateNew(termId: $termId, termUpdateInput: $termUpdateInput) {
      id
      supplierId
      fromDate
      type: typeNew
      paymentMethod
      paymentDueNet
      beforeBilling
      directDebitDay
      directDebit
      bankAccountId
      eom
    }
  }
`;

const TERM_UPDATE_MUTATION = gql`
  mutation termUpdateMutation($termId: ID!, $termUpdateInput: TermUpdateInput!) {
    termUpdate(termId: $termId, termUpdateInput: $termUpdateInput) {
      id
      supplierId
      fromDate
      type: typeNew
      paymentMethod
      paymentDueNet
      beforeBilling
      directDebitDay
      directDebit
      bankAccountId
      eom
    }
  }
`;
