import SimpleSchema from 'simpl-schema';

const getWrappingAutoformComponent = (component) => {
  let parentComponent = component.$parent;
  while (parentComponent && parentComponent.$options.name !== 'Autoform') {
    parentComponent = parentComponent.$parent;
  }
  return parentComponent;
};

const getValue = (model, fieldKey) => {
  if (!fieldKey) return model;
  return fieldKey.split('.').reduce((model, key) => model[key], model);
};

export default {
  name: 'autoinput',
  props: ['fieldKey', 'component'],
  computed: {
    genericFieldKey() {
      return this.fieldKey.replace(/\.\d+(?=\.|$)/g, '.$');
    },
    fieldValue() {
      return getValue(this.autoform.model, this.fieldKey);
    },
    fieldDefinition() {
      const definition = this.autoform.schema.getDefinition(this.genericFieldKey);
      if (this.genericFieldKey.endsWith('.$')) return { ...definition, label: '' };
      else return definition;
    },
    keyType() {
      return this.fieldDefinition && this.fieldDefinition.type[0].type;
    },
    fieldSlot() {
      if (this.$scopedSlots.default) return this.$scopedSlots.default;
      let component = this;
      while (component) {
        if (['Autoform', 'autoinput'].includes(component.$options.name)) {
          if (component.$scopedSlots[this.genericFieldKey]) {
            return component.$scopedSlots[this.genericFieldKey];
          }
        }
        component = component.$parent;
      }
    },
    rule() {
      const { schema, model } = this.autoform;
      const validator = (rule, value, callback) => {
        const errors = [];
        const context = schema.newContext();
        if (!context.validate(context.clean(model), { keys: [rule.field] })) {
          errors.push(context.keyErrorMessage(rule.field));
        }
        const namedContext = schema.namedContext();
        if (namedContext.keyIsInvalid(rule.field)) {
          const definition = schema.getDefinition(rule.field);
          const messages = namedContext
            .validationErrors()
            .filter(({ name }) => name === rule.field)
            .map((error) => schema.messageBox.message(error, { context: definition }));
          errors.push(...messages);
        }
        if (errors.length) callback(errors.join(', '));
        else callback();
      };
      return { validator, trigger: 'none' };
    },
  },
  methods: {
    focus() {
      this.$el.querySelector('input').focus();
    },
    checkAndAddEventListener() {
      if (this.keyType === Array || this.keyType instanceof SimpleSchema) return;
      if (!this.$el.querySelector) return;
      this.$el.querySelector('input').addEventListener('focus', () => {
        this.autoform.actualFocusedField = this.fieldKey;
      });
      this.$el.querySelector('input').addEventListener('blur', () => {
        this.autoform.actualFocusedField = null;
      });
      if (this.keyType === Number) {
        this.$el.querySelector('input').addEventListener('wheel', (e) => {
          e.target.blur();
        });
      }
    },
  },
  render(createElement) {
    const onInput = (value) => {
      const keys = this.fieldKey.split('.');
      const lastKey = keys.pop();
      const parentModel = getValue(this.autoform.model, keys.join('.'));
      if (Number.isNaN(+lastKey)) parentModel[lastKey] = value;
      // not an array element
      else this.$set(parentModel, +lastKey, value);
    };

    const props = {
      fieldKey: this.fieldKey,
      fieldDefinition: this.fieldDefinition,
      value: this.fieldValue,
      rule: this.rule,
    };

    const on = {
      input: onInput,
    };

    if (this.keyType === Array) {
      props.elemFieldDefinition = this.autoform.schema.getDefinition(`${this.genericFieldKey}.$`);
    }

    if (this.component) return createElement(this.component, { props, on });

    if (this.fieldSlot) {
      const scope = { ...props, ...on };
      return createElement('div', [this.fieldSlot(scope)]);
    }

    const dataObj = { props, on };

    if (this.keyType instanceof SimpleSchema) {
      return createElement('autoform-object', dataObj);
    }
    if (this.keyType === Array) {
      return createElement('autoform-array', dataObj);
    }
    return createElement('autoform-other', dataObj);
  },
  beforeCreate() {
    this.$options.components.AutoformOther = require('./autoform_other.vue').default;
    this.$options.components.AutoformObject = require('./autoform_object.vue').default;
    this.$options.components.AutoformArray = require('./autoform_array.vue').default;
    this.autoform = getWrappingAutoformComponent(this);
  },
  mounted() {
    this.checkAndAddEventListener();
  },

  updated() {
    this.checkAndAddEventListener();
  },
};
