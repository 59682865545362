import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const useTerms = (variables) => {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(TERMS_QUERY, variables);
  const terms = computed(() => result?.value?.termsNew?.nodes?.[0] || []);

  onError((err) => {
    console.error('useTerms', err);
    error();
  });

  return {
    terms,
    loading,
    refetch,
  };
};

const TERMS_QUERY = gql`
  query terms($type: TermTypeNew, $supplierId: ID, $businessId: ID) {
    termsNew(type: $type, supplierId: $supplierId, businessId: $businessId) {
      nodes {
        id
        supplierId
        fromDate
        customerNumber
      }
    }
  }
`;
