<template>
  <el-form ref="form" :model="model" v-bind="$attrs">
    <slot>
      <autoinput v-for="key in schema.objectKeys()" :key="key" :field-key="key" />

      <div class="d-flex justify-content-end mt-4">
        <el-button type="primary" @click="submit">Submit</el-button>
        <el-button @click="reset">Reset</el-button>
      </div>
    </slot>
  </el-form>
</template>

<script type="text/javascript">
import SimpleSchema from 'simpl-schema';
import Autoinput from './autoinput';
import { deepCopy, getModelFromSchema } from './autoform_utils';

const findAutoinput = (component, fieldKey) => {
  if (component.$options.name === 'autoinput') {
    if (component.$props.fieldKey === fieldKey) return component;
    if (!fieldKey.startsWith(`${component.$props.fieldKey}.`)) return;
  }
  for (let child of component.$children) {
    const result = findAutoinput(child, fieldKey);
    if (result) return result;
  }
};

export default {
  name: 'Autoform',
  components: { Autoinput },
  props: {
    schema: SimpleSchema,
    model: {
      type: Object,
      default: () => ({}),
    },
    defaultValue: { type: Object, default: () => {} },
    focusedField: { type: String, default: () => null },
  },
  data() {
    return { actualFocusedField: undefined };
  },
  watch: {
    model: {
      handler() {
        this.$emit('change');
      },
      deep: true,
    },
    schema: {
      handler(value) {
        const newModel = getModelFromSchema(value, this.model);
        this.switchModel(newModel);
      },
      immediate: true,
    },
    defaultValue: {
      handler(value) {
        const newModel = getModelFromSchema(this.schema, value);
        this.switchModel(newModel);
      },
      immediate: true,
    },
    focusedField: {
      handler(value) {
        if (!value || value === this.actualFocusedField) return;
        this.$nextTick(() => {
          const autoinput = this.getAutoinput(value);
          autoinput.focus();
        });
      },
      immediate: true,
    },
    actualFocusedField: {
      handler(value) {
        if (value !== this.focusedField) this.$emit('update:focusedField', value);
      },
    },
  },
  methods: {
    switchModel(newModel) {
      Object.keys(this.model).forEach((key) => {
        this.$delete(this.model, key);
      });
      Object.keys(newModel).forEach((key) => {
        this.$set(this.model, key, newModel[key]);
      });
    },
    getData() {
      return deepCopy(this.model);
    },
    setData(data) {
      const newModel = getModelFromSchema(this.schema, Object.assign({}, this.model, data));
      this.switchModel(newModel);
    },
    setField(fieldKey, value) {
      const keys = fieldKey.split('.');
      const lastKey = keys.pop();
      const parentModel = keys.reduce((model, key) => model[key], this.model);
      parentModel[lastKey] = value;
    },
    getField(fieldKey) {
      return fieldKey.split('.').reduce((model, key) => model[key], this.model);
    },
    submit() {
      this.$refs.form.validate((validated) => {
        if (validated) {
          this.$emit('submit', deepCopy(this.model));
        }
      });
    },
    reset() {
      this.$refs.form.resetFields();
    },
    getAutoinput(fieldKey) {
      return findAutoinput(this, fieldKey);
    },
  },
};
</script>
