import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export const useCreateTerm = () => {
  const { mutate, loading, onDone, onError } = useMutation(TERM_CREATE_MUTATION);

  return {
    createTerm: mutate,
    loading,
    onDone,
    onError,
  };
};

const TERM_CREATE_MUTATION = gql`
  mutation termCreateMutation($termCreateInput: TermCreateInput!) {
    termCreate(termCreateInput: $termCreateInput) {
      id
    }
  }
`;

export const useCreateTermNew = () => {
  const { mutate, loading, onDone, onError } = useMutation(TERM_CREATE_MUTATION_NEW);

  return {
    createTerm: mutate,
    loading,
    onDone,
    onError,
  };
};

const TERM_CREATE_MUTATION_NEW = gql`
  mutation termCreateMutation($termCreateInput: TermCreateInputNew!) {
    termCreateNew(termCreateInput: $termCreateInput) {
      id
    }
  }
`;
